import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyB9r8jRRiyGPKyeL3uM00eZiSVeG9xJ0gQ",
  authDomain: "movie-4581d.firebaseapp.com",
  databaseURL: "https://movie-4581d.firebaseio.com",
  projectId: "movie-4581d",
  storageBucket: "movie-4581d.appspot.com",
  messagingSenderId: "509745671612",
  appId: "1:509745671612:web:b2aab353ca11f5a4324fb9"
}; 
export const app = initializeApp(firebaseConfig);
export const storage = getStorage(app);