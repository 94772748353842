
import { getDownloadURL, ref, uploadBytesResumable ,listAll} from 'firebase/storage';
import { useState } from 'react';
import { ProgressBar} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import {storage} from "./firebase";

function App() {
  let url = ""
  const [progress ,setProgress]=useState(0);
  const [File, setFile ]= useState(url);
  
  
  const uploadFiles =(file) =>{
  
    if (!file) return;
    let path = "/"+file.name
    const storageRef = ref(storage,path)
    const uploadTask = uploadBytesResumable(storageRef,file);
    uploadTask.on("state_changed",
    (snapshot)=>{
      const prog =Math.round( (snapshot.bytesTransferred /snapshot.totalBytes)*100);
      setProgress(prog);
    },


      (err)=> console.log(err),
      () =>{
        let v_url = getDownloadURL(uploadTask.snapshot.ref).then(((url)=>{console.log(url);setFile(url)}));
        setFile(v_url);
        console.log(v_url);
      }
  
  )
}
const listRef = ref(storage);

// Find all the prefixes and items.
listAll(listRef)
  .then((res) => {
    res.prefixes.forEach((folderRef) => {
   
      // All the prefixes under listRef.
      // You may call listAll() recursively on them.
    });
    res.items.forEach((itemRef) => {
     console.log(itemRef.path)

    
   
     
      // All the items under listRef.
    });
  }).catch((error) => {
    // Uh-oh, an error occurred!
  });

const formHandler =(e)=>{
  e.preventDefault();
  const files = e.target[0].files;
  const file_keys= Object.keys(files)
  console.log(typeof(files))
  console.log(file_keys)
 
  console.log(files);
  file_keys.forEach((item_number)=>{console.log(item_number);const file = files[item_number];console.log(file);uploadFiles(file);setProgress(0)})
  

}

let p = "Uploading"+""+" "+progress +"%";
if(progress==100){
  let p = "Done Uploading"
}


 

  return (
    <div className="App">
      <form onSubmit={formHandler}>
        <input type="file" className="input" multiple/>
        <button type="submit">Upload File </button>
      </form>
      
      
      <ProgressBar now={progress} label={p}  />
      <img src={File}/>
      <video autoplay  controls src={File} >

      </video>

      
    </div>
  );
}

export default App;
